/* App.css */
.container-fluid {
    color: #fff;
  }
  
  .column {
    padding: 20px;
    border:1px solid transparent;
    border-color: hsla(0,0%,100%,.15);
    margin: 0px;
  }
  
  .column ul {
    list-style-type: none;
    padding: 0;
  }
  
  .column ul li {
    cursor: pointer;
    padding: 8px;
    border:1px solid transparent;
    border-color: hsla(0,0%,100%,.15);
    border-radius: 10px;
    margin-bottom: 10px;
    transition: 0.3s;
  }
 
  h3{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .category-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .category-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    position: relative;
  }
  .category-item span{
    color:#efefef;
    opacity: 7;
  }  
  .category-item:hover,.category-item.active{
    background-color: #3b3b3b;
    color: #ffffff;
    border-color: hsla(0,0%,100%,.30);
  }
  .category-item:hover span,.category-item.active span{
    color: #ffffff;
    opacity: 1;
  }
  
  .category-name {
    flex-grow: 1;
  }
  
  .icon-group {
    display: flex;
    gap: 8px;
    visibility: hidden; /* Hide icons initially */
  }
  
  .category-item:hover .icon-group {
    visibility: visible; /* Show icons on hover */
  }
  
  .icon {
    color: #888;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  .icon:hover {
    color: #000; /* Change color on hover */
  }
  
  .active {
    background-color: #f0f8ff; /* Optional: highlight active item */
  }
  
  .modal{
    width: 100%;
    padding: 20px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    color: #fff;
  }
  .modal-content{
    background-color: #212121;
  }
  .modal-header{
    border: none;
  }
  .modal-footer{
    border: none;
  }
  .modal-header .btn-close{
    background-color: transparent;
    color: #f5f5f5;
    font-size: 12px;
    padding: 8px;
  }

  .modal-title{
    color:#f5f5f5;
    font-size: 18px;
    font-weight: 600;
  }

  .nhpcfile{opacity: 1!important; margin-bottom: 10px;}
  .nhpcfile:hover{
    background-color: transparent;
    border-color: hsla(0,0%,100%,.30);
    color:#fff;
    box-shadow: none;
  }
  .nhpcfile:focus{
    background-color: transparent;
    border-color: hsla(0,0%,100%,.30);
    color:#fff;
    box-shadow: none;
  }

  .btn-primary {
    background-color: #B6DA50;
    border-color: hsla(0,0%,100%,.15);
    color:#000;
    border-radius: 20px;
    padding: 5px 20px;
    transition: 0.3s;
  }

  .btn-secondary{
    background-color: transparent;
    border-radius: 20px;
    padding: 5px 20px;
  }

  .btn-primary:hover,.btn-primary:active,.btn-primary:focus{
    background-color: #fff;
    color:#000;
    border-color: hsla(0,0%,100%,.15);
  }

  .form-check-input:checked{
    background-color: #B6DA50;
    border-color: #B6DA50;
  }

  .table{
    --bs-table-bg:transparent;
    --bs-table-color: #f5f5f5;
    border-color: hsla(0,0%,100%,.15);
  }

  .form-control{
    background-color: transparent;
    border-color: hsla(0,0%,100%,.30);
    color:#fff;
  }
  .form-control:focus{
    background-color: transparent;
    border-color: hsla(0,0%,100%,.30);
    color:#fff;
    box-shadow: none;
  }
  .form-control:active{
    background-color: transparent;
    border-color: hsla(0,0%,100%,.30);
    color:#fff;

  }
  .form-control::placeholder{
    color:#fff;
  }

  .form-label{
    margin-top: 15px;
    font-size: 14px;
  }

  .form-select{
    background-color: transparent;
    border-color: hsla(0,0%,100%,.30);
    color:#fff;
  }

  option{
    background-color: transparent;
    border-color: hsla(0,0%,100%,.30);
    color:#fff;
  }

  .loading-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
    z-index: 1999; /* Ensure it appears above other content */
  }
  
  .link{color:#C4F048;}


  /* Style the select dropdown */
.parentfile-list select {
  background-color: #333; /* Dark background for dropdown */
  color: #fff; /* Light text for contrast */
  border:1px solid transparent;
  border-color: hsla(0,0%,100%,.15);
  -webkit-appearance: button;
  -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  -webkit-padding-end: 20px;
  -webkit-padding-start: 2px;
  -webkit-user-select: none;
}

/* Style for the options */
.parentfile-list select option {
  background-color: #333; /* Dark background for options */
  color: #fff; /* Light text */
  border: none; /* Remove border */
  outline: none; /* Remove focus outline */
}

/* Option for changing background color on hover */
.parentfile-list select option:hover {
  background-color: #444; /* Darker shade for hover effect */
  border: none; /* Remove border */
  outline: none; /* Remove focus outline */
}

.select-dropdown.nhpc{width: 100%; margin-bottom: 10px;}
.smallheading{font-size: 14px; color: #f0f0f0;}
.nav__bar__link.nhpc{padding: 0;}
.left__sidebar ul.nav__bar__link.nhpc li{width:100%;}
.left__sidebar ul.nav__bar__link.nhpc li div{width: 100%;}
.left__sidebar ul.nav__bar__link.nhpc li a{width:100%;}

.custom-scrollbar.nhpc{
  height: 76vh;
}

.pagenumber{font-size: 12px;}